<template>
  <div>
    <div class="card shadow">
      <div class="pt-4 pb-4 pe-4 ps-4" @keyup.enter="$emit('getRaasList')">
        <div class="row pb-4">
          <div class="col-sm-4 filter">
            <div class="dp__input_wrap text-gray-600">
              <input
                name="Denominazione"
                type="text"
                placeholder="Denominazione"
                class="form-control"
                :value="denominazioneTesseramentiRaas"
                @input="setDenominazioneTesseramentiRaas($event.target.value)"
              />
            </div>
          </div>
          <div class="col-sm-4 filter">
            <div class="dp__input_wrap text-gray-600">
              <input
                name="cod_fiscale"
                type="text"
                placeholder="Codice fiscale"
                class="form-control"
                :value="codiceFiscTesseramentiRaas"
                @input="setCodiceFiscTesseramentiRaas($event.target.value)"
              />
            </div>
          </div>
          <div class="col-sm-4 filter">
            <div class="dp__input_wrap text-gray-600">
              <input
                name="cod_affiliazione"
                type="text"
                placeholder="Codice affiliazione"
                class="form-control"
                :value="codiceAffTesseramentiRaas"
                @input="setCodiceAffTesseramentiRaas($event.target.value)"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3 filter">
            <SelectInput
              placeholder="Comitato"
              :options="comitati"
              name="id_comitato"
              :value="comitatoTesseramentiRaas"
              @changeSelect="setComitatoTesseramentiRaas"
            />
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('1')"
              @click="
                da_inviare = !da_inviare;
                setDaInviareTesseramentiRaas();
              "
            />
            <label class="fw-bold ps-1 text-gray-800"> Da inviare</label>
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('2')"
              @click="
                inviate = !inviate;
                setInviateTesseramentiRaas();
              "
            />
            <label class="fw-bold ps-1 text-gray-800"> Corr. Inviata</label>
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('3')"
              @click="
                errori = !errori;
                setErroriTesseramentiRaas();
              "
            />
            <label class="fw-bold ps-1 text-gray-800"> Errori su invio </label>
          </div>
          <div class="col-sm-2 align-self-center text-center">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="stati_invio && stati_invio.includes('4')"
              @click="
                da_reinviare = !da_reinviare;
                setDaReinviareTesseramentiRaas();
              "
            />
            <label class="fw-bold ps-1 text-gray-800"> Da reinviare</label>
          </div>
        </div>
        <div class="text-center mt-5">
          <button
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600"
            @click="$emit('getRaasList')"
            :disabled="!loaded"
          >
            Cerca <i class="bi bi-search fs-6 text-gray-600"> </i>
          </button>
          <span
            type="button"
            data-bs-toggle=""
            data-bs-target=""
            aria-expanded="false"
            aria-controls=""
            class="badge rounded-pill bg-light text-gray-600 ms-4"
            @click.prevent="$emit('resetFilters')"
          >
            <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue3-date-time-picker/dist/main.css";
import { defineComponent, computed, ref, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";

export default defineComponent({
  name: "filtri-affiliazioni-raas",
  emits: ["resetFilters", "getRaasList"],
  components: {
    SelectInput,
  },

  setup(props, { emit }) {
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const comitati = computed(() => store.getters.getStateFromName("comitati"));

    const keys = ref("c");
    if (!comitati.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keys.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const stagioneAffiliazioneRaas = computed(
      () => store.getters.stagioneSelected
    );
    watch(stagioneAffiliazioneRaas, () => {
      emit("getRaasList");
    });

    const denominazioneTesseramentiRaas = computed(
      () => store.getters.denominazione_raas_tesseramenti
    );
    const setDenominazioneTesseramentiRaas = (event) => {
      store.commit("setDenominazione_raas_tesseramenti", event);
    };
    const codiceFiscTesseramentiRaas = computed(
      () => store.getters.codice_fiscale_raas_tesseramenti
    );
    const setCodiceFiscTesseramentiRaas = (event) => {
      store.commit("setCodice_fiscale_raas_tesseramenti", event);
    };
    const codiceAffTesseramentiRaas = computed(
      () => store.getters.codice_affiliazione_raas_tesseramenti
    );
    const setCodiceAffTesseramentiRaas = (event) => {
      store.commit("setCodice_affiliazione_raas_tesseramenti", event);
    };
    const comitatoTesseramentiRaas = computed(
      () => store.getters.id_comitato_raas_tesseramenti
    );
    const setComitatoTesseramentiRaas = (event) => {
      store.commit("setId_comitato_raas_tesseramenti", event);
    };
    const daInviareTesseramentiRaas = computed(
      () => store.getters.stati_invio_raas_tesseramenti
    );
    const setDaInviareTesseramentiRaas = () => {
      store.dispatch("setDaInviareTesseramentiRaas");
    };
    const stati_invio = computed(
      () => store.getters.stati_invio_raas_tesseramenti
    );
    const setInviateTesseramentiRaas = () => {
      store.dispatch("setInviateTesseramentiRaas");
    };
    // const erroriTesseramentiRaas = computed(
    //   () => store.getters.erroriTesseramentiRaas
    // );
    const setErroriTesseramentiRaas = () => {
      store.dispatch("setErroriTesseramentiRaas");
    };
    // const daReinviareTesseramentiRaas = computed(
    //   () => store.getters.daReinviareTesseramentiRaas
    // );
    const setDaReinviareTesseramentiRaas = () => {
      store.dispatch("setDaReinviareTesseramentiRaas");
    };

    return {
      comitati,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedraas_tesseramenti_list")
      ),
      denominazioneTesseramentiRaas,
      codiceFiscTesseramentiRaas,
      codiceAffTesseramentiRaas,
      comitatoTesseramentiRaas,
      daInviareTesseramentiRaas,
      stati_invio,
      // inviateTesseramentiRaas,
      // erroriTesseramentiRaas,
      // daReinviareTesseramentiRaas,
      setDenominazioneTesseramentiRaas,
      setCodiceFiscTesseramentiRaas,
      setCodiceAffTesseramentiRaas,
      setComitatoTesseramentiRaas,
      setDaInviareTesseramentiRaas,
      setInviateTesseramentiRaas,
      setErroriTesseramentiRaas,
      setDaReinviareTesseramentiRaas,
    };
  },
});
</script>

<style></style>
