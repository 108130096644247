<template>
  <div>
    <FilterTesseratiRaas
      @getRaasList="searchRaasList"
      @resetFilters="resetFilters"
    />
    <TableTesseratiRaas
      @getRaasList="getRaasList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  watch,
} from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import FilterTesseratiRaas from "@/components/components-fit/raas/FiltriTesseratiRaas.vue";
import TableTesseratiRaas from "@/components/components-fit/raas/TableTesseratiRaas.vue";

export default defineComponent({
  name: "affiliazioni-raas",
  components: {
    FilterTesseratiRaas,
    TableTesseratiRaas,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Raas");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);

    const data_ultimo_invio = computed(
      () => store.getters.data_ultimo_invio_raas_tesseramenti
    );
    const denominazione = computed(
      () => store.getters.denominazione_raas_tesseramenti
    );

    const codice_fiscale = computed(
      () => store.getters.codice_fiscale_raas_tesseramenti
    );
    const codice_affiliazione = computed(
      () => store.getters.codice_affiliazione_raas_tesseramenti
    );
    const id_comitato = computed(
      () => store.getters.id_comitato_raas_tesseramenti
    );
    const stati_invio = computed(
      () => store.getters.stati_invio_raas_tesseramenti
    );

    const rowsToSkip = computed(
      () => store.getters.rowtoskip_raas_tesseramenti
    );
    const fetchRows = computed(() => store.getters.fetchrows_raas_tesseramenti);
    const sortColumn = computed(
      () => store.getters.sortcolumn_raas_tesseramenti
    );
    const sortOrder = computed(() => store.getters.sortorder_raas_tesseramenti);

    const getRaasList = () => {
      const localKeys = {
        anno: anno.value,
        data_ultimo_invio: data_ultimo_invio.value,
        denominazione: denominazione.value,
        codice_fiscale: codice_fiscale.value,
        codice_affiliazione: codice_affiliazione.value,
        id_comitato: id_comitato.value,
        stati_invio: stati_invio.value,
        rowtoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.RAAS_TESSERAMENTI,
        itemName: "raas_tesseramenti_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersTesseramentiRaas");
      getRaasList();
    };
    getRaasList();

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedraas_tesseramenti_list")
    );
    const searchRaasList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipTesseramentiRaas");
      getRaasList();
    };

    watch(anno, () => {
      searchRaasList();
    });

    return {
      getRaasList,
      resetFilters,
      searchRaasList,
    };
  },
});
</script>
